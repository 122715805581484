import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckoutService } from '../../../services/checkout.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {

  hours: string[];
  appointmentForm: FormGroup;
  loader = false;
  numbersArray = [1, 2, 3, 4, 5, 6];
  @ViewChild('exampleModal') exampleModal: any;
  
  constructor(private fb: FormBuilder, private checkoutService: CheckoutService) {
    this.appointmentForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      waterSport: ['Jet Ski', Validators.required],
      date: [formatDate(new Date(), 'yyyy-MM-dd', 'en'), [Validators.required, this.validarFecha.bind(this)]],
      hourRental: ['100', Validators.required],
      hour: ['8:00 am', Validators.required],
      ramp: ['Clearwater Beach', Validators.required],
      cantEquip: ['1', Validators.required]
    });
    this.hours = this.generateHours(8, 19);
  }


  validarFecha(control: FormControl): { [s: string]: boolean } {
    if (control.value) {
      const fechaIngresada = new Date(control.value);
      const fechaActual = new Date();
      const fechaIngresadaLocal = new Date(fechaIngresada.getTime() + fechaIngresada.getTimezoneOffset() * 60000);

      fechaIngresadaLocal.setHours(0, 1, 0, 0);
      fechaActual.setHours(0, 0, 1, 0);

      if (fechaIngresadaLocal < fechaActual) return { 'fechaInvalida': true};
      
      return null; 
  }
}

  generateHours(startHour: number, endHour: number): string[] {
    const hours = [];
    for (let i = startHour; i <= endHour; i++) {
      const hour = i % 12 || 12; // Obtener la hora en formato de 12 horas
      const amPm = i < 12 ? 'am' : 'pm'; // Determinar si es "am" o "pm"
      hours.push(`${hour}:00 ${amPm}`);
    }
    return hours;
  }

  getOptionText(value: string): string {
    switch (value) {
      case '0.5': return "Test - $1 + 0.1 gas";
      case '70': return '30 min - $70 + $10 gas';
      case '100': return '1 hour - $100 + $10 gas';
      case '160': return '90 min - $160 + $15 gas';
      case '200': return '2 hours - $200 + $20 gas';
      case '300': return '3 hours - $300 + $30 gas';
      case '400': return '4 hours - $400 + $40 gas';
      case '600': return '8 hours - $600 + $50 gas';
      default: return 'Test';
    }
  }

  getPriceGasoil(value: string): number {
    switch (value) {
      case '0.5': return 0;
      case '70': return 10;
      case '100': return 10;
      case '160': return 15;
      case '200': return 20;
      case '300': return 30;
      case '400': return 40;
      case '600': return 50;
      default: return 0;
    }
  }

  onSubmit() {
    if (this.appointmentForm.valid) {
      this.loader = true;
      const name = this.appointmentForm.get('name').value;
      const email = this.appointmentForm.get('email').value;
      const phone = this.appointmentForm.get('phone').value;
      const waterSport = this.appointmentForm.get('waterSport').value;
      const date = this.appointmentForm.get('date').value;
      const hourRentalPrice = this.appointmentForm.get('hourRental').value;
      const hour = this.appointmentForm.get('hour').value;
      const ramp = this.appointmentForm.get('ramp').value;
      const cantEquip = this.appointmentForm.get('cantEquip').value;
      
      const hourRentalText = this.getOptionText(hourRentalPrice);
      const priceGasoil = Number(this.getPriceGasoil(hourRentalPrice)) * cantEquip;

      const price = Number(hourRentalPrice) *  cantEquip
      const objeto = { name: name, email: email, phone: phone, waterSport: waterSport, date: date, hourRental: hourRentalText, hour: hour, ramp: ramp, cantEquip: cantEquip};

      const objetoString = JSON.stringify(objeto);

      localStorage.setItem('reserva', objetoString);
      this.checkoutService.onProccedToPay(cantEquip + " - " + waterSport + " Retal", price, priceGasoil);
    }
  }

}

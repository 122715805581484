<div class="header-top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-9 col-lg-9">
                <div class="header-top-item">
                    <div class="header-top-left">
                        <ul>
                            <li><a href="mailto:rideintampajetski@gmail.com"><i class="icofont-ui-message"></i> rideintampajetski@gmail.com</a>
                            </li>
                            <li><i class="icofont-location-pin"></i> Clearwater Beach | Fort De Soto Boat Ramp | Causeway Boat Ramp | Seminole Boat Ramp</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="header-top-item">
                    <div class="header-top-right">
                        <ul>
                            <li><a href="https://www.instagram.com/rideintampa/" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="https://wa.me/19783761106" target="_blank"><i class="icofont-whatsapp"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area sticky-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo-two.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand logo" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HOME</a></li>
                        <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">BOOKING</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">CONTACT US</a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">ABOUT US</a></li>
                    </ul>

                </div>
            </nav>
        </div>
    </div>
</div>
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { loadStripe } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  private readonly _http =  inject(HttpClient);
  private readonly _url =  environment.serverUrl;

  onProccedToPay(name: string, price: number, priceGas: number) {
    const items = priceGas != 0 
                      ? 
                          [{ name: name, price: price }, {name: 'Gas', price: priceGas} ] 
                      :   
                          [{ name: name, price: price }] ;
    return this._http.post(`${this._url}/checkout`, { items: items})
      .pipe(
        map(async (res: any) => {
          const stripe = await loadStripe(environment.apikey);
          stripe.redirectToCheckout({ sessionId: res.id })
        })
      ).subscribe((res) => {
        console.log(res);
      })
  }

}
<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>About</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>About</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-two/flayer.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <h2>About Our Bussines</h2>
                    <p>Discover the excitement in every wave with Ride in Tampa, your destination for unforgettable aquatic adventures. With a fleet of state-of-the-art jetskis, we offer you the freedom to sail the sea at your own pace. Our commitment to safety, fun and customer service sets us apart. Embark on a unique experience and immerse yourself in the adrenaline with us.</p>

                    <ul>
                        <li><i class="icofont-check-circled"></i> Safety</li>
                        <li><i class="icofont-check-circled"></i> Exciting</li>
                        <li><i class="icofont-check-circled"></i> Innovative</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ptb-100"></div>
<app-preloader *ngIf="loader"></app-preloader>
<div class="home-slider home-slider-two owl-theme owl-carousel">
    <div class="slider-item slider-item-img-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <h1>We Have Fantastic Jetski & Flyboard</h1>
                        <p></p>

                        <div class="common-btn">
                            <a routerLink="/appointment">Get Booking</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img-three">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <h1>We Always Provide Best Services</h1>
                        <p></p>

                        <div class="common-btn">
                            <a routerLink="/appointment">Get Booking</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img-four">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <h1>High Adventures And Unlimited Fun</h1>
                        <p></p>

                        <div class="common-btn">
                            <a routerLink="/appointment">Get Booking</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="emergency-area">
    <div class="container">
        <div class="row emergency-bg">
            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-ui-call"></i>
                    <div class="emergency-inner">
                        <h3>Call</h3>
                        <p>+1 978-376-1106</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-file-document"></i>
                    <div class="emergency-inner">
                        <h3>Booking Easily</h3>
                        <div class="newsletter-form">
                            <button class="btn btn-lg btn-primary" type="submit" routerLink="/appointment">Go to
                                Booking</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-location-pin"></i>
                    <div class="emergency-inner">
                        <h3>Ramps available</h3>
                        <p>Clearwater Beach | Fort De Soto Boat Ramp | Causeway Boat Ramp | Seminole Boat Ramp</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="welcome-area pt-100 pb-70">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-left">
                    <img src="assets/img/home-two/watersport.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="welcome-item welcome-right">
                    <div class="section-title-two">
                        <span>About Us</span>
                        <h2>Welcome to Ride in Tampa. We have...</h2>
                    </div>

                    <ul>
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <i class="icofont-energy-water"></i>
                            <div class="welcome-inner">
                                <h3>Water Sports</h3>
                                <p>Discover the excitement of water sports with us. Guaranteed fun for the whole
                                    family!.
                                </p>
                            </div>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <i class="icofont-snowmobile"></i>
                            <div class="welcome-inner">
                                <h3>Jet Ski Rental</h3>
                                <p>Rent your own jet ski and enjoy speed and freedom on the water. Book now!.
                                </p>
                            </div>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <i class="icofont-sail-boat-alt-2"></i>
                            <div class="welcome-inner">
                                <h3>Fly Board Rental</h3>
                                <p>Experience the sensation of flying over the water with our flyboard rental service.
                                    An unique and unforgettable experience!.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="speciality-area wow animate__animated animate__fadeInUp">
    <div class="row">
    <div class="container-fluid pt-4 col-lg-6  col-sm-12 pb-4">
        <div class="section-title-two">
            <span>Safety orientation</span>
            <h2>Personal Watercraft Safety</h2>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <iframe width="860" height="415"  src="https://www.youtube.com/embed/79imqxkLwfU?si=W3QYd_amaCSu6M2x" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid pt-4 col-lg-6 col-sm-12 pb-4">
        <div class="section-title-two">
            <span>Certificate</span>
            <h2>Florida boating temporary</h2>
        </div>
        <div class="row">
            <div class="col-lg-12 ">
                <div class="blog-item">
                    <div class="d-flex justify-content-center">
                        <a href="https://boattests101.com/united-states/florida/florida-rental?mcid=xoUUwzSO"><img src="assets/img/qrboat.jpg" width="400" height="300"
                                alt="boattest"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a>Florida boating temporary certificate</a></h3>
                        <p></p>
                        <ul>
                            <li>
                            </li>
                            <li><a style="cursor: pointer" href="https://boattests101.com/united-states/florida/florida-rental?mcid=xoUUwzSO">Go to <i class="icofont-long-arrow-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>



<section class="speciality-area wow animate__animated animate__fadeInUp">
    <div class="container-fluid pt-50 col-12">
        <div class="section-title-two">
            <span>Available</span>
            <h2>Ramps</h2>
        </div>
        <div class="row">
            <div class="contact-map-area col-sm-12 col-lg-6">
                <div class="container">
                    <div class="section-title-two">
                        <span>Clearwater Beach, FL</span>
                        <h5>69 Bay Esplanade, Clearwater Beach, FL 33767</h5>
                    </div>
                    <div id="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.307365306824!2d-82.82649522380447!3d27.98449117602483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2f6cd9c968c93%3A0x60b911d909e2c58c!2s69%20Bay%20Esplanade%2C%20Clearwater%20Beach%2C%20FL%2033767%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sdo!4v1708727873715!5m2!1ses-419!2sdo"
                            width="800" style="border:0; height: auto;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <div class="contact-map-area col-sm-12 col-lg-6">
                <div class="container">
                    <div class="section-title-two">
                        <span>Fort De Soto Boat Ramp, FL</span>
                        <h5>3500 Pinellas Bayway S, Tierra Verde, FL 33715, United States</h5>
                    </div>
                    <div id="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3534.321114375391!2d-82.71982942376562!3d27.645535076218035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c31b366ea384ef%3A0x9a79901bfd0efe3f!2sFort%20De%20Soto%20Boat%20Ramp!5e0!3m2!1sen!2sdo!4v1712163082753!5m2!1sen!2sdo"
                            width="600" height="450" style="border:0; height: auto;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <div class="contact-map-area col-sm-12 col-lg-6">
                <div class="container">
                    <div class="section-title-two">
                        <span>Causeway Boat Ramp, FL</span>
                        <h5>W Courtney Campbell Cswy, Tampa, FL 33615, United States</h5>
                    </div>
                    <div id="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.7157270997145!2d-82.61274438953922!3d27.971991013670586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2e9a609938011%3A0xcbef80d2a1c78e2d!2sCauseway%20Boat%20Ramp!5e0!3m2!1sen!2sdo!4v1712163064166!5m2!1sen!2sdo"
                            width="600" height="450" style="border:0; height: auto;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <div class="contact-map-area col-sm-12 col-lg-6">
                <div class="container">
                    <div class="section-title-two">
                        <span>Seminole Boat Ramp, FL</span>
                        <h5>198 Seminole St, Clearwater, FL 33755, United States</h5>
                    </div>
                    <div id="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.642447629499!2d-82.80506838953914!3d27.974234513570796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2f121ac14aaf7%3A0xefd5793250a617e8!2sSeminole%20Boat%20Ramp!5e0!3m2!1sen!2sdo!4v1712162940354!5m2!1sen!2sdo"
                            width="600" height="450" style="border:0; height: auto;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="speciality-area wow animate__animated animate__fadeInUp">
    <div class="container-fluid pt-50 col-12">
        <div class="section-title-two">
            <span>Contracts</span>
            <h2>Waiver contracts</h2>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div  class="doctor-item">
                    <div class="doctor-bottom p-2">
                        <h5>LIVERY PRE-RENTAL AND PRE-RIDE INSTRUCTION</h5>
                        <span></span>
                        <a href="https://waiver.smartwaiver.com/w/ilxvw8hkedhxrnbipqgi4j/web/" class="btn btn-primary col-12 mt-2" >Sign</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div  class="doctor-item">
                    <div class="doctor-bottom p-2">
                        <h5>PARTICIPANT AGREEMENT, RELEASE AND ASSUMPTION OF RISK</h5>
                        <span></span>
                        <a href="https://waiver.smartwaiver.com/w/dxfsqdkg49grkdxnbyrhzk/web/" class="btn btn-primary col-12 mt-2" >Sign</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div  class="doctor-item">
                    <div class="doctor-bottom p-2">
                        <h5>PERPETUAL WAIVER AND RELEASE</h5>
                        <span></span>
                        <a href="https://waiver.smartwaiver.com/w/fc4v4gw3jjupbnaa4tjzg6/web/" class="btn btn-primary col-12 mt-2" >Sign</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div  class="doctor-item">
                    <div class="doctor-bottom p-2">
                        <h5>PROTECTIVE WETSUIT REFUSAL AGREEMENT</h5>
                        <span></span>
                        <a href="https://waiver.smartwaiver.com/w/hkegfgzjwqohg3odyr8ekh/web/" class="btn btn-primary col-12 mt-2" >Sign</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-two">
            <span>Shop</span>
            <h2>Products and Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a><img src="https://m.media-amazon.com/images/I/61r2vbet7iL._AC_SX679_.jpg" alt="Goggle" height="590"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a>Rent Goggle for Jetski</a></h3>
                        <form [formGroup]="hourForm">
                            <div class="col-lg-12 mb-3">
                                <div class="form-group">
                                    <i class="icofont-stopwatch"></i>
                                    <label>Time of rent</label>
                                    <select class="form-control" formControlName="hourRental"
                                        (change)="onChange($event.target.value)">
                                        <option value="5">1 hour - $5</option>
                                        <option value="10">2 hours - $10</option>
                                        <option value="15">3 hours - $15</option>
                                        <option value="20">4 hours - $20</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <ul>
                            <li>
                            </li>
                            <li><a  style="cursor: pointer" (click)="onPay('Rent Goggle for Jetski', priceRent)" >Rent for ${{priceRent}}<i class="icofont-long-arrow-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a><img src="https://i5.walmartimages.com/asr/e87867c8-78ec-4518-b8af-e4715fe2a8ec.6d5b164557054d391bc1684aae9532a0.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF"
                                alt="balaclava"></a>
                    </div>

                    <div class="blog-bottom">
                        <h3><a>Buy a balaclava mask</a></h3>
                        <p></p>
                        <ul>
                            <li>
                            </li>
                            <li><a style="cursor: pointer" (click)="onPay('Balaclava mask', 5)">Buy for $5 <i class="icofont-long-arrow-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
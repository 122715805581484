import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { PayCompleteComponent } from './components/pages/pay-complete/pay-complete.component';

const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    //{path: '', component: ComingSoonComponent},
    {path: 'about', component: AboutComponent},
    {path: 'appointment', component: AppointmentComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'pay-complete', component: PayCompleteComponent},
    {path: '**', component: ErrorComponent} 
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
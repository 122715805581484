<footer class="pb-70 pt-100">
    <div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <h2>Booking Easily</h2>
                        <p>Don't wait any longer to live fun experiences.</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <div class="newsletter-form">
                                <button class="btn newsletter-btn" type="submit" routerLink="/appointment">Go to Booking</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container pt-100">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h3>Contact Us</h3>
                        <ul>
                            <li>
                                <i class="icofont-ui-message"></i>
                                <a href="mailto:rideintampajetski@gmail.com"> rideintampajetski@gmail.com</a>
                            </li>
                            <li>
                                <i class="icofont-stock-mobile"></i>
                                <a href="tel:+19783761106">Call: +1 978-376-1106</a>
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>
                                Clearwater Beach | Fort De Soto Boat Ramp | Causeway Boat Ramp | Seminole Boat Ramp
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/appointment">Booking</a></li>
                            <li><a routerLink="/about">About us</a></li>
                            <li><a routerLink="/contact">Contact us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Our Services</h3>
                        <ul>
                            <li><a routerLink="/appointment">Jetski Rental</a></li>
                            <li><a routerLink="/appointment">Flyboard Rental</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-feedback">
                        <img src="assets/img/home-two/flayer.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>©Ride In Tampa - Water sports Jetski & Flyboard | Developed by <a href="mailto:reyfivasquez@gmail.com" target="_blank">REYFI</a></p>
        </div>
    </div>
</div>
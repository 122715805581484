<app-preloader *ngIf="loader"></app-preloader>
<div class="page-title-area page-title-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Get Your  Appointment</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Appointment</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="appointment-area-two">
    <div class="container">
        <div class="row align-items-center appointment-wrap-two">
            <div class="col-lg-7 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div class="appointment-item appointment-item-two">

                    <h2>Book your appointment</h2>

                    
                    <div class="appointment-form pt-3">
                        <form [formGroup]="appointmentForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-business-man-alt-1"></i>
                                        <label>Name</label>
                                        <input type="text" class="form-control" formControlName="name" placeholder="Enter Your Name"
                                               [class.is-invalid]="appointmentForm.get('name').invalid && appointmentForm.get('name').touched">
                                        <div *ngIf="appointmentForm.get('name').invalid && appointmentForm.get('name').touched"
                                             class="invalid-feedback">
                                            Please enter your name.
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-ui-message"></i>
                                        <label>Email</label>
                                        <input type="email" class="form-control" formControlName="email" placeholder="Enter Your Email" 
                                        [class.is-invalid]="appointmentForm.get('email').invalid && appointmentForm.get('email').touched">
                                        <div *ngIf="appointmentForm.get('email').invalid && appointmentForm.get('email').touched"
                                        class="invalid-feedback">
                                       Please enter your email.
                                   </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-ui-call"></i>
                                        <label>Phone</label>
                                        <input type="text" class="form-control" formControlName="phone" placeholder="Enter Your Number"                                              
                                        [class.is-invalid]="appointmentForm.get('phone').invalid && appointmentForm.get('phone').touched">
                                        <div *ngIf="appointmentForm.get('phone').invalid && appointmentForm.get('phone').touched"
                                             class="invalid-feedback">
                                            Please enter your phone.
                                        </div>
                                    </div>
                                </div>

                                
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-location-pin"></i>
                                        <label>Meeting ramp</label>
                                        <select class="form-control" formControlName="ramp">
                                            <option value="Clearwater Beach">Clearwater Beach</option>
                                            <option value="Fort De Soto Boat Ramp">Fort De Soto Boat Ramp</option>
                                            <option value="Causeway Boat Ramp">Causeway Boat Ramp</option>
                                            <option value="Seminole Boat Ramp">Seminole Boat Ramp</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <i class="icofont-energy-water"></i>
                                        <label>Water Sport</label>
                                        <select class="form-control" formControlName="waterSport">
                                            <option value="Jet Ski">Jet Ski</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <i class="icofont-ui-calendar"></i>
                                        <label>Date</label>
                                        <input type="Date" class="form-control" formControlName="date" placeholder="Enter Your Date"  
                                        [class.is-invalid]="appointmentForm.get('date').invalid && appointmentForm.get('date').touched">
                                        <div *ngIf="appointmentForm.controls['date'].hasError('fechaInvalida') && appointmentForm.controls['date'].touched"
                                             class="invalid-feedback">
                                            Please enter a valid date.
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <i class="icofont-stopwatch"></i>
                                        <label>Time</label>
                                        <select class="form-control" formControlName="hour">
                                            <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-numbered"></i>
                                        <label>Amount of equipment to rent</label>
                                        <select class="form-control" formControlName="cantEquip">
                                            <option *ngFor="let number of numbersArray" [value]="number">{{number}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <i class="icofont-stopwatch"></i>
                                        <label>Hours to rent</label>
                                        <select class="form-control" formControlName="hourRental">
                                            <option value="70">30 min - $70 + $10 gas</option>
                                            <option value="100">1 hour - $100 + $10 gas</option>
                                            <option value="160">90 min - $160 + $15 gas</option>
                                            <option value="200">2 hours - $200 + $20 gas</option>
                                            <option value="300">3 hours - $300 + $30 gas</option>
                                            <option value="400">4 hours - $400  + $40 gas</option>
                                            <option value="600">8 hours - $600  + $50 gas</option>
                                        </select>
                                    </div>
                                </div>



                                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Open Booking Requierements
                                  </button>

                                <button type="button" class="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                    Cancellation policy
                                  </button>
                            </div>


                            <div class="text-center pt-3">
                                <button type="submit" class="btn appointment-btn">Booking</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>

            <div class="col-lg-5 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                <div class="appointment-item-two-right">
                    <div class="appointment-item-content">
                        <h2>Working Hours</h2>
                        <div class="content-one">
                            <ul>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>Saturday</li>
                                <li>Sunday</li>
                            </ul>
                        </div>
                        <div class="content-two">
                            <ul>
                                <li>8:00 am - 7:00 pm</li>
                                <li>8:00 am - 7:00 pm</li>
                                <li>8:00 am - 7:00 pm</li>
                                <li>8:00 am - 7:00 pm</li>
                                <li>8:00 am - 7:00 pm</li>
                                <li>8:00 am - 7:00 pm</li>
                                <li>8:00 am - 7:00 pm</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


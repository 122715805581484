import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutService } from '../../../services/checkout.service';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {

  hours: string[];
  hourForm: FormGroup;
  priceRent : number = 5
  loader = false;
  
  constructor(private fb: FormBuilder, private checkoutService: CheckoutService) { 
    this.hourForm = this.fb.group({
      hourRental: ['5', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  onChange(value: number) {
    this.priceRent = value
  }

  onPay(name: string, price: number) {
    this.loader = true
    localStorage.setItem('shop', 'true');
    this.checkoutService.onProccedToPay(name, price, 0)
  }

}

import { Injectable } from '@angular/core';
import * as emailjs from 'emailjs-com';

@Injectable({
  providedIn: 'root'
})
export class EmailjsService {

  constructor() {
    emailjs.init('LRMb3y0a13AwEPd0_'); 
  }

  enviarCorreo(destinatario: string, email: string, nombre: string, fecha: string, horario : string, hora : string, equipo : string, phone: string, ramp: string, cantEquip: string, template: string): Promise<any> {
    const emailParams = {
        to_email: destinatario,
        email: email,
        nombre: nombre,
        fecha: fecha,
        horario: horario,
        horas: hora,
        equipo: equipo,
        phone: phone,
        ramp: ramp,
        cantEquip: cantEquip
    };

    return emailjs.send('ridein_service_email', template, emailParams); 
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailjsService } from 'src/app/services/emails.service';

@Component({
  selector: 'app-pay-complete',
  templateUrl: './pay-complete.component.html',
  styleUrls: ['./pay-complete.component.scss']
})
export class PayCompleteComponent implements OnInit {

  objeto : any;
  shop : boolean = false;
  constructor(private emailjsService: EmailjsService, private router: Router) { }

  ngOnInit(): void {
    const _shop = localStorage.getItem('shop');

    if (_shop !== null) {
      this.shop = true
    } else {
      this.objeto = JSON.parse(localStorage.getItem('reserva'));
      this.emailjsService.enviarCorreo(this.objeto.email, this.objeto.email, this.objeto.name, this.objeto.date, this.objeto.hour, this.objeto.hourRental, this.objeto.waterSport, this.objeto.phone, this.objeto.ramp, this.objeto.cantEquip, 'template_reservation');
      this.emailjsService.enviarCorreo("rideintampajetski@gmail.com", this.objeto.email, this.objeto.name, this.objeto.date, this.objeto.hour, this.objeto.hourRental, this.objeto.waterSport, this.objeto.phone, this.objeto.ramp, this.objeto.cantEquip, 'template_2');
    }

    setTimeout(() => {
      localStorage.clear();
    }, 5000);
  }

  home(){
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

}

<div class="page-title-area page-title-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Contact Us</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Contact us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="emergency-area">
    <div class="container">
        <div class="row emergency-bg">
            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-ui-call"></i>
                    <div class="emergency-inner">
                        <h3>Call</h3>
                        <p>+1 978-376-1106</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-file-document"></i>
                    <div class="emergency-inner">
                        <h3>Booking Easily</h3>
                        <div class="newsletter-form">
                            <button class="btn btn-lg btn-primary" type="submit" routerLink="/appointment">Go to
                                Booking</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="emergency-item">
                    <i class="icofont-location-pin"></i>
                    <div class="emergency-inner">
                        <h3>Ramps available</h3>
                        <p>Clearwater Beach | Fort De Soto Boat Ramp | Causeway Boat Ramp | Seminole Boat Ramp</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="speciality-area wow animate__animated animate__fadeInUp">
    <div class="container-fluid pt-100 pb-5">
        <div class="row m-0">
            <div class="col-lg-12">
                <div class="speciality-left">
                    <div class="section-title-two">
                        <span>Social network</span>
                        <h2>Instagram</h2>
                    </div>
                </div>
                <script src="https://embedsocial.com/js/iframe.js"></script><iframe
                    style="border: 0; width: 100%; height: 620px;" scrolling="no"
                    src="https://embedsocial.com/api/pro_hashtag/93019d77d10374c9ed3c4f866790e6d7bbb6b7c5"></iframe>
                <script>iFrameResize();</script>
            </div>
        </div>
    </div>
</section>

<div class="col-12 pt-30 mb-5 pb-5">

    <section class="speciality-area wow animate__animated animate__fadeInUp">
        <div class="container-fluid pt-50 col-12">
            <div class="section-title-two">
                <span>Available</span>
                <h2>Ramps</h2>
            </div>
            <div class="row">
                <div class="contact-map-area col-sm-12 col-lg-6">
                    <div class="container">
                        <div class="section-title-two">
                            <span>Clearwater Beach, FL</span>
                            <h5>69 Bay Esplanade, Clearwater Beach, FL 33767</h5>
                        </div>
                        <div id="map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.307365306824!2d-82.82649522380447!3d27.98449117602483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2f6cd9c968c93%3A0x60b911d909e2c58c!2s69%20Bay%20Esplanade%2C%20Clearwater%20Beach%2C%20FL%2033767%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sdo!4v1708727873715!5m2!1ses-419!2sdo"
                                width="800" style="border:0; height: auto;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div class="contact-map-area col-sm-12 col-lg-6">
                    <div class="container">
                        <div class="section-title-two">
                            <span>Fort De Soto Boat Ramp, FL</span>
                            <h5>3500 Pinellas Bayway S, Tierra Verde, FL 33715, United States</h5>
                        </div>
                        <div id="map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3534.321114375391!2d-82.71982942376562!3d27.645535076218035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c31b366ea384ef%3A0x9a79901bfd0efe3f!2sFort%20De%20Soto%20Boat%20Ramp!5e0!3m2!1sen!2sdo!4v1712163082753!5m2!1sen!2sdo"
                                width="600" style="border:0; height: auto;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div class="contact-map-area col-sm-12 col-lg-6">
                    <div class="container">
                        <div class="section-title-two">
                            <span>Causeway Boat Ramp, FL</span>
                            <h5>W Courtney Campbell Cswy, Tampa, FL 33615, United States</h5>
                        </div>
                        <div id="map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.7157270997145!2d-82.61274438953922!3d27.971991013670586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2e9a609938011%3A0xcbef80d2a1c78e2d!2sCauseway%20Boat%20Ramp!5e0!3m2!1sen!2sdo!4v1712163064166!5m2!1sen!2sdo"
                                width="600" style="border:0; height: auto;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div class="contact-map-area col-sm-12 col-lg-6">
                    <div class="container">
                        <div class="section-title-two">
                            <span>Seminole Boat Ramp, FL</span>
                            <h5>198 Seminole St, Clearwater, FL 33755, United States</h5>
                        </div>
                        <div id="map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3523.642447629499!2d-82.80506838953914!3d27.974234513570796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2f121ac14aaf7%3A0xefd5793250a617e8!2sSeminole%20Boat%20Ramp!5e0!3m2!1sen!2sdo!4v1712162940354!5m2!1sen!2sdo"
                                width="600"  style="border:0; height: auto;" allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</div>
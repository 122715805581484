<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <div class="image-container">
                        <img src="assets/img/check.png" alt="Imagen" class="center-image">
                    </div>

                    <div *ngIf="shop; else elseBlock">
                        <p>Thanks for your purchase</p>
                    </div>
                      
                      <ng-template #elseBlock>
                        <p>Appointment made correctly!</p>
                        <span>We have sent a confirmation email to {{ objeto.email }}.</span>
                      </ng-template>
                </div>
                <a (click)="home()" style="color: white; cursor: pointer">Return to Home</a>
            </div>
        </div>
    </div>
</section>
<app-preloader></app-preloader>
<app-navbar *ngIf="!(location == '/coming-soon')"></app-navbar>
    <router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon')"></app-footer>


  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Booking Requierements</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <pre>
Upon your arrival theres surcharge fee a $300 refundable deposit that you will receive back. Long as there is no damage to the watercraft, 
and you stay within our watercraft zone of operation.

Prior to Booking, please read the following requirements

AGE REQUIREMENTS:

    YOU MUST BE 18 YEARS OF AGE TO RENT A PERSONAL WATERCRAFT IN FLORIDA

    16 OR OLDER IF ACCOMPANIED BY A PARENT OR GUARDIAN.

    No minimum age for passengers.

FLORIDA RENTAL EXAM:

    For anyone born after January 1st, 1988:

    ​Per State Law, In order to rent a jet ski in the State of Florida, 
    you must complete the https://boattests101.com/united-states/florida/florida-rental?mcid=PWwpihe2 The cost of the exam is $13.95 and consists of 20-25 questions. 
    The cost and exam are controlled by the State and has no affiliation with Ride in Tampa. Only the driver needs the exam. A Passenger does not need the exam. 

    You are also required to watch our safety jet ski video.

Guests should have no physical or mental conditions that would preclude him/her from participating in Ride in Tampa activities. Do not participate in Ride in Tampa activities against medical advice.

Combined weight limit to ride can not exceed 415 lbs.​

If you understand and agree with the Requirements, click the Book Now below and get ready to ride! We can't wait to see you in the water!
          </pre>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Cancellation policy</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <pre>


Cancellations made less than 72 hours prior to the reservation time will incur a 100% charge. In the event of 
inclement weather, we get you wet waterSport will reschedule or refund you.


          </pre>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>